html,
body {
  height: 100%;
  overflow-anchor: none;
  overflow: hidden;
}

#root {
  height: 100%;
  overflow: hidden;
  overflow-anchor: none;
}

@media print {
  #root {
    overflow: auto;
  }

  html,
  body {
    height: auto;
    overflow: auto;
  }
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}
